import { FC } from 'react';

import { Layer, LayerName } from '@hh.ru/magritte-ui';

import SearchType from 'src/components/NovaFilters/SearchType';
import ResumeSearchScrollToTop from 'src/components/SearchScrollToTop/Resume';
import VacancySearchScrollToTop from 'src/components/SearchScrollToTop/Vacancy';
import SearchPreferenceButton from 'src/components/SearchSorts/SearchPreferenceButton';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const SearchFooterButtons: FC = () => {
    const searchType = useSelector((state) => state.searchClustersSettings.type);

    return (
        <Layer layer={LayerName.Alpha}>
            <div className={styles.buttonsContainer}>
                {searchType === SearchType.Vacancy && <VacancySearchScrollToTop />}
                {searchType === SearchType.Resume && <ResumeSearchScrollToTop />}
                <SearchPreferenceButton />
            </div>
        </Layer>
    );
};

export default SearchFooterButtons;
